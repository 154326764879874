import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './slices/userSlice';
import userAlert from './slices/toastSlice';
import loadingReducer from './slices/loadingSlice';
import userPermission from './slices/userPermission';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["user", "permission"]
}

const rootReducer = combineReducers({
  user: userReducer,
  notifications: userAlert,
  loading: loadingReducer,
  permission: userPermission,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});
export const persistor = persistStore(store);
export default store;