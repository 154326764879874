import { Drawer } from "antd"
import { close, logo_thaco_blue_v2 } from "./imgExport"
import {
    FileOutlined} from '@ant-design/icons';
import './styles.css'
import { Link } from "react-router-dom";
import { useState } from "react";
interface Props {
    isOpen: boolean
    dissmist: () => void
}
const LeftMenu: React.FC<Props> = ({
    isOpen,
    dissmist
}) => {
    const [actived, setActived] = useState(1)
    const changeValueMenu = (value: number) => {
        setActived(value)
        dissmist()
    }
    return (
        <Drawer
            className="custom-btn-none d-leftMenu custom-bg-menu"
            title={
                <div className="flex items-center justify-between">
                    <img src={logo_thaco_blue_v2} className='object-cover z-10' alt="" />
                    <span className="anticon anticon-close" onClick={dissmist}>
                        <img src={close} alt="" />
                    </span>
                </div>
            }
            placement="left"
            onClose={dissmist}
            open={isOpen}
        >
            <div className="flex flex-col">
                {/* */}
                <Link
                    onClick={() => changeValueMenu(1)}
                    to={'/home'}
                    className={`item-menu-mobie ${actived === 1 ? 'active' : ''}`}><FileOutlined className="pr-2" />
                    Tài liệu nội bộ
                </Link>
                {/* <Link
                    onClick={() => changeValueMenu(2)}
                    to={'/internal-documents'}
                    className={`item-menu-mobie ${actived === 2 ? 'active' : ''}`}> <FileOutlined className="pr-2" />
                    Tài liệu nội bộ
                </Link>
                <Link
                    onClick={() => changeValueMenu(3)}
                    to={'/search-document'}
                    className={`item-menu-mobie ${actived === 3 ? 'active' : ''}`}><FilterOutlined className="pr-2" />
                    Tìm kiếm tài liệu
                </Link> */}
            </div>
        </Drawer>
    )
}
export default LeftMenu
