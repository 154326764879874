import axios from "axios";
import qs from 'qs';
import * as ENV from "../env";
import tokenService from "../service/token"

export interface ResponseSuccess<T> {
    success?: boolean;
    data: T;
    message: string;
}

export interface ResponseSuccessPortal<T> {
    success?: boolean;
    data: T;
    status: boolean
    message: string;
}

export interface ResponsePostSuccess {
    success: boolean;
    message: string;
}

export interface ResponseError {
    status: number,
    title: string,
    errors: {
        [key: string]: string[]
    }
}

const axiosClientUser = axios.create({
    baseURL: ENV.API_URL,
    headers: {
        'content-type': 'multipart/form-data',
    },
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true });
        }
    }
});

axiosClientUser.interceptors.request.use(async (config) => {
    const token = tokenService.getToken();
    if (token)
        config.headers.Authorization = 'Bearer ' + token;
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClientUser.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
}, (error) => {
    if (error.response && error.response.data && error.response.data.error &&
        (error.response.data.session === false || error.response.data.session === "false")) {
        window.location.href = "/";
    } else if (error?.response && error.response?.data && error.response.data.error && error.response.data.error.message) {
        return error?.response?.data
    } else if (error.response && error.response.status === 500) {
        if (error.response.data?.message.search("DELETE") > -1) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    } else if (error.response && error.response.status === 401) {
        tokenService.removeToken();
        window.location.href = "/";
    } else {
        return error.response.data;
    }
});

export default axiosClientUser;