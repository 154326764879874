import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoadingSlice {
    show: boolean,
}

const initialLoadingState: ILoadingSlice = {
    show: false,
}

const userSlice = createSlice({
    name: 'loading',
    initialState: initialLoadingState,
    reducers: {
        setLoading: (state, action: PayloadAction<ILoadingSlice>) => action.payload,
        setShow: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload
        },
        clearLoading: (state) => initialLoadingState
    }
})

export const selectorLoading = (state: { loading: ILoadingSlice }) => state.loading;
export const selectorShow = (state: { loading: ILoadingSlice }) => state.loading.show
export const { setLoading, setShow, clearLoading } = userSlice.actions;
export default userSlice.reducer;