import { Avatar, Badge, Button, Dropdown, MenuProps, Popover, Space } from 'antd'
import {
    BellOutlined, UserOutlined
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import tokenService from '../service/token';
import { clearPermission } from '../store/slices/userPermission';
import { clearUser } from "../store/slices/userSlice";
import { useDispatch } from 'react-redux';
import './styles.css'
import { logOut } from '../components/imgExport';
import NotificationComponent from './Notification';
import * as NotificationApi from '../api/NotificationApi'
import { useEffect, useState } from 'react';
import useToast from '../hook/useToast';
import * as DocumentApi from '../api/DocumentApi/index';
import * as ReportApi from '../api/ReportApi/index';
import { MenuApi } from '../api/LoginApi/loginApi';

export default function PersonalAdmin() {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [allNotification, setAllNotification] = useState<NotificationApi.ListThongBao[]>([]);
    const [allNotificationUnRead, setAllNotificationUnread] = useState<NotificationApi.ListThongBao[]>([]);
    const [totalNotificaUnread, setTotalNotificaUnread] = useState<number>(0);
    const [totalNotifica, setTotalNotifica] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10)
    const [offset, setOffset] = useState<number>(1)
    const [offsetAll, setOffsetAll] = useState<number>(1)
    const [items, setItems] = useState<MenuProps['items']>([]);
    const navigator = useNavigate();
    const pushAlert = useToast();
    const handlePopoverVisibleChange = (popoverVisible: boolean) => {
        if (popoverVisible) {
            setOffset(1)
            fetchDataUnread(1, limit)
            setOffsetAll(1)
            fetchData(1, limit)
        }
        setVisible(popoverVisible);
    };

    const handleScroll = (limit: number, offsetAll: number, load: boolean) => {
        setOffsetAll(offsetAll)
        fetchData(offsetAll, limit)
    };

    const fetchData = async (pageNum: number, pageSize: number) => {
        try {
            const result = await NotificationApi.getNotification(pageNum, pageSize)
            if (result.success) {
                const convertData: NotificationApi.ListThongBao[] = result.data.rows.map((item) => (
                    {
                        id: item.id,
                        document: item.noidung,
                        name: item.ThongBaoAdmin.fullname,
                        date: new Date(item.ngay_tao).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }),
                        time: new Date(item.ngay_tao).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        }),
                        tailieu_id: item.tailieu_id,
                        baocao_id: item.baocao_id,
                        status: item.status,
                        danhmucbaocao_id: item.danhmucbaocao_id
                    }))
                if (pageNum === 1) {
                    setAllNotification(convertData)
                } else {
                    setAllNotification(prevData => prevData.concat(convertData))
                }
                setTotalNotifica(result.data.count)
            } else {
                setAllNotification([])
                setTotalNotifica(0)
            }
        } catch (error) {
        }
    }

    const handleDetail = async (tailieu_id: number | null, baocao_id: number | null, id: number, danhmucbaocao_id: number | null) => {
        try {
            let detail = await NotificationApi.updateNotification(id)
            if (detail.success) {
                if (tailieu_id) {
                    let detail = await DocumentApi.detailTaiLieu(tailieu_id)
                    if (detail.success) {
                        window.location.href = `/document-list-detail/${tailieu_id}`;
                    } else {
                        pushAlert("Tài liệu không tồn tại!", "warn", 2000);
                    }
                } else if (baocao_id) {
                    let detail = await ReportApi.detailReport(baocao_id)
                    if (detail.success) {
                        navigator('/upload-report', { state: { baocao_id } });
                    } else {
                        pushAlert("Báo cáo không tồn tại!", "warn", 2000);
                    }
                } else if (danhmucbaocao_id) {
                    navigator('/add-report', { state: { danhmucbaocao_id } });
                } else {
                    pushAlert("Tài liệu hoặc báo cáo đã xóa!", "warn", 2000);
                }
            }
        } catch (error) {
            pushAlert("Tài liệu không tồn tại!", "warn", 2000);
        }
    }

    const onLogout = () => {
        tokenService.removeToken()
        dispatch(clearUser());
        dispatch(clearPermission());
        localStorage.removeItem("menu");
        navigator("/");
    }

    const handleScrollUnRead = (limit: number, offset: number, load: boolean) => {
        setOffset(offset)
        fetchDataUnread(offset, limit)
    };

    const fetchDataUnread = async (pageNum: number, pageSize: number) => {
        try {
            const result = await NotificationApi.getNotificationUnRead(pageNum, pageSize)
            if (result.success) {
                const convertData: NotificationApi.ListThongBao[] = result.data.rows.map((item) => (
                    {
                        id: item.id,
                        document: item.noidung,
                        name: item.ThongBaoAdmin.fullname,
                        date: new Date(item.ngay_tao).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        }),
                        time: new Date(item.ngay_tao).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        }),
                        tailieu_id: item.tailieu_id,
                        baocao_id: item.baocao_id,
                        status: item.status,
                        danhmucbaocao_id: item.danhmucbaocao_id
                    }))
                if (pageNum === 1) {
                    setAllNotificationUnread(convertData)
                } else {
                    setAllNotificationUnread(prevData => prevData.concat(convertData))
                }
                setTotalNotificaUnread(result.data.count)
            } else {
                setAllNotificationUnread([])
                setTotalNotificaUnread(0)
            }
        } catch (error) {
        }
    }

    const updateNotificationAll = async () => {
        try {
            let detail = await NotificationApi.updateNotificationAll()
            if (detail.success) {
                setOffsetAll(1)
                fetchData(1, limit)
                setOffset(1)
                fetchDataUnread(1, limit)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        handleScrollUnRead(limit, 1, false)
        const result = tokenService.getMenu();
        const data: MenuApi[] = result ? JSON.parse(result ?? '') : []
        setItems([
            {
                key: '1',
                label: (
                    <Link to={'/personal'}>Thông tin cá nhân</Link>

                ),
            },
            {
                key: '2',
                label: (
                    <Link to={`${data.filter((value) => value.type === true)[0] ? data.filter((value) => value.type === true)[0].menu_url : '/home'}`} className="text-[#096DD9]">Trang Portal</Link>
                ),
            },
            {
                key: '3',
                label: (
                    <div onClick={onLogout} className="text-[#F64C4C] flex items-center gap-2">Đăng xuất <img src={logOut} alt="" /></div>
                ),
            },
        ])
    }, [])

    return (
        <div className="flex items-center gap-0  sm:gap-1 ">
            <Button className='flex items-center border-0 p-1 sm:px-3 '>
                <Popover placement="bottomRight"
                    content={<NotificationComponent
                        handleDetail={handleDetail}
                        allNotification={allNotification}
                        handleScroll={handleScroll}
                        handleScrollUnRead={handleScrollUnRead}
                        allNotificationUnRead={allNotificationUnRead}
                        updateNotificationAll={updateNotificationAll}
                        totalNotificaUnread={totalNotificaUnread}
                        limit={limit}
                        offset={offset}
                        setOffset={setOffset}
                        offsetAll={offsetAll}
                        setOffsetAll={setOffsetAll}
                        totalNotifica={totalNotifica}
                    />}
                    visible={visible}
                    onVisibleChange={handlePopoverVisibleChange}
                >
                    {allNotificationUnRead.length > 0 ? <Badge className='text-xs' dot>
                        <BellOutlined className='text-xl text-white ' />
                    </Badge> : <Badge className='text-xs'>
                        <BellOutlined className='text-xl text-white ' />
                    </Badge>}
                </Popover>
            </Button>
            <Dropdown menu={{ items, selectable: true, }} arrow className='cursor-pointer'>
                <Space wrap size={32}>
                    <Avatar size={32} icon={<UserOutlined />} />
                </Space>
            </Dropdown>
        </div>
    )
}
