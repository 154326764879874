import {
  FileOutlined,
  FilterOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, MenuProps } from "antd";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import './styles.css';
import { Thaco_logo } from '../components/imgExport';
import LeftMenu from '../components/LeftMenuMobile';
import PersonalPortal from './PersonalPortal';
import PersonalQLTD from './PersonalQLTD';

export default function LayoutQLTD() {
  const [collapsed, setCollapsed] = useState(false);
  const { Header, Sider, Content } = Layout;
  const [showMenu, setShowMenu] = useState<boolean>(false)
  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const rootSubmenuKeys = ['sub', 'sub0', 'sub1'];
  const items: MenuItem[] = [
    getItem(<Link to={'/signing-management'}> Quản lý trình ký</Link>, 'qltk', <FileOutlined />,),
    getItem(<Link to={'/request-management'}> Quản lý trình duyệt</Link>, 'qltd', <FileOutlined />,),
    getItem(<Link to={'/approval-management-mobile-preview'}> Quản lý phê duyệt</Link>, 'qlpd', <FileOutlined />,),
  ];

  const [openKeys, setOpenKeys] = useState(['sub0']);
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div className='layout2'>
      <LeftMenu
        isOpen={showMenu}
        dissmist={() => setShowMenu(!showMenu)}
      />
      <Layout className='w-full h-[100vh] overflow-hidden m-0 bg-[#F5F6FA]'>
        <Header className='flex w-full justify-between items-center pl-0 pr-6 bg-[#01498B] relative m-0'>
          <div className='flex items-center'>
            <div className="disabled sm-block">
              <Button
                type="text"
                className='text-white custom-button-color flex items-center justify-center '
                icon={<MenuOutlined />}
                onClick={() => setShowMenu(!showMenu)}
              />
            </div>
            <div className="flex items-center gap-3 px-3">
              <Link to='/signing-management' className='p-0 m-0 '>
                <div className="h-[63px] flex items-center justify-center z-20">
                  <img width={191} src={Thaco_logo} className=' object-cover z-10' alt="" />
                </div>
              </Link>
            </div>
          </div>
          <PersonalQLTD />
        </Header>
        <Layout >
          <Sider className='sm-disabled bg-white transition-all' trigger={null} collapsible collapsed={collapsed} onMouseEnter={() => setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}>
            <div className='min-h-[100vh] bg-white'>
              <div className="bg-white">
                <Menu
                  className='custom-menu-title text-item-8892b0'
                  openKeys={openKeys}
                  theme="light"
                  mode="inline"
                  onOpenChange={onOpenChange}
                  defaultSelectedKeys={['1']}
                  items={items}
                />
              </div>
            </div>
          </Sider>
          <Content
            className='h-full bg-[#F5F6FA] overflow-y-scroll'>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
