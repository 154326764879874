
import { useDispatch } from 'react-redux';
import { createAlert } from '../store/slices/toastSlice';
const useAlert = () => {
    const dispatch = useDispatch();

    const pushAlert = (message: string, icon: "success" | "error" | "warn" | "info", timer: number = 0) => {
        dispatch(createAlert({
            message,
            icon,
            timer
        }))
    }

    return pushAlert;
}

export default useAlert;