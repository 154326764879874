import { useSelector } from 'react-redux';
import { selectorPermission } from '../store/slices/userPermission';
import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { dataRouterExcept } from '../utils';

interface element {
    element: React.ReactElement
}
const PrivateRoute: React.FC<element> = ({ element }) => {
    const token = localStorage.getItem('token');
    const location = useLocation();
    let isAuthenticated = true;
    let pessmistion = true;
    const dataMenu = useSelector(selectorPermission)
    const { permission } = dataMenu;
    if (token === null) {
        isAuthenticated = false
    } else {
        var url = (location.pathname).split("/")[1];
        if (!dataRouterExcept.includes(location.pathname) && location.pathname.search("add") < 0 && location.pathname.search("update") < 0 && location.pathname.search("detail") < 0) {
            if (!permission.includes("/" + url)) {
                pessmistion = false
            }
        }
    }
    if (isAuthenticated === false) {
        if (location.pathname.includes('/detail-news/')) {
            sessionStorage.setItem('urlback', location.pathname)
        }
    }
    return isAuthenticated === false ? (
        <Navigate to="/login" />
    ) : pessmistion === false ? <Navigate to="/401page" /> : (element);
    // return isAuthenticated === false ? (
    //     <Navigate to="/login" />
    // ) : pessmistion === false ? (element) : (element);
};
export default PrivateRoute;
