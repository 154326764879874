import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { AllNotification, Unread } from './Notifica';
import '../styles.css'
import * as NotificationApi from '../../api/NotificationApi'
import { useState } from 'react';

interface AllNoti {
    handleScroll: (limit: number, offset: number, load: boolean) => void
    handleDetail: (tailieu_id: number | null, baocao_id: number | null, id: number, danhmucbaocao_id: number | null,) => void
    allNotification: NotificationApi.ListThongBao[]
    handleScrollUnRead: (limit: number, offset: number, load: boolean) => void
    allNotificationUnRead: NotificationApi.ListThongBao[]
    updateNotificationAll: () => void
    totalNotificaUnread: number
    setOffset: (value: number) => void
    offset: number
    limit: number
    totalNotifica: number
    setOffsetAll: (value: number) => void
    offsetAll: number
}
const NotificationComponent: React.FC<AllNoti> = ({handleDetail, allNotification, handleScroll, allNotificationUnRead, handleScrollUnRead, updateNotificationAll, totalNotificaUnread, limit, offset, setOffset, offsetAll, setOffsetAll, totalNotifica}) => {
    const [changeTab, setChangeTab] = useState<string>('1');
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Tất cả',
            children: <AllNotification 
                        handleDetail={handleDetail} 
                        allNotification={allNotification} 
                        handleScroll={handleScroll} 
                        limit={limit}
                        offsetAll={offsetAll}
                        setOffsetAll={setOffsetAll}
                        totalNotifica={totalNotifica}
                        />,
        },
        {
            key: '2',
            label: 'Chưa xem',
            children: <Unread 
                        handleScrollUnRead={handleScrollUnRead} 
                        handleDetail={handleDetail} 
                        allNotificationUnRead={allNotificationUnRead} 
                        totalNotificaUnread={totalNotificaUnread}
                        limit={limit}
                        offset={offset}
                        setOffset={setOffset}
                        />,
        },
        // {
        //     key: '3',
        //     label: 'Demo',
        //     children: <DemoScroll />,
        // },
    ];

    return (
        <div className='flex flex-col w-popup bg-white'>
            <div className="w-full flex items-center justify-between px-6 pt-[10px]">
                <span className="text-base font-[600] text-[#4B4B4B]">Thông báo</span>
                <span className="text-xs font-[400] text-[#8E8E8E] cursor-pointer" onClick={updateNotificationAll}>Xem tất cả</span>
            </div>
            <Tabs activeKey={changeTab} items={items} className="custom-tab" onChange={(e) => setChangeTab(e)}/>
        </div>
    );
};

export default NotificationComponent;