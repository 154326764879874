import { Modal, Button, Pagination, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./styles.scss";
import { useEffect, useState } from "react";
import * as LibApi from "../../api/LibApi/index";
import env from "../../env";
import { ModalUpload } from "../../pages/Libs/Modal/ModalUpload";
import { fail } from "../imgExport";

interface Props {
  className?: string;
  onChange?: (e: any) => void;
  option?: any;
  dataDanhSachLib: LibApi.HinhAnh[];
  pagination: LibApi.Pagination;
  imageSelect: string | null;
  handleChangeList: (value: string) => void;
  isShow?: any;
  handlePaginationChange: (page: number, size: number) => void
}
const LibraryIndex: React.FC<Props> = ({
  className,
  onChange,
  option,
  dataDanhSachLib,
  pagination,
  imageSelect,
  handleChangeList,
  isShow,
  handlePaginationChange
}) => {
  const librAvt: React.CSSProperties = {
    aspectRatio: option?.aspectRatio || "1/1",
  };
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const { Search } = Input;
  const changeToUplaod = () => {
    setOpen(false);
    setOpenUpload(true);
  };
  const resetData = () => {
    setOpenUpload(false);
    setOpen(true);
    handleChangeList("");
  };
  useEffect(() => {
    if (isShow) {
      setOpen(true);
    }
  }, [isShow]);
  return (
    <>
      <div className={`libr-main ${className}`}>
        <div className="libr-avt" style={librAvt} onClick={showModal}>
          {imageSelect && (
            <img
              className="img"
              src={env.process.env.IMAGE_LIB_URL + imageSelect}
              alt="avatar"
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = fail;
              }}
            />
          )}
          <PlusOutlined className="libr-icon" />
        </div>
      </div>
      <Modal
        open={open}
        title="Thư viện lưu trữ"
        onCancel={() => setOpen(false)}
        footer={[]}
        className="libr-modal"
        width={1366}
      >
        <div className="libr-modal-head">
          <Search
            placeholder="Tìm kiếm"
            enterButton
            onChange={(e) => handleChangeList(e.target.value)}
          />
          <Button type="primary" onClick={() => changeToUplaod()}>
            Tải ảnh lên
          </Button>
        </div>
        <div className="libr-list">
          {dataDanhSachLib.map((item, index) => (
            <div
              key={index}
              className="libr-item"
              onClick={() => {
                setOpen(false);
                onChange && onChange(item.id);
              }}
            >
              <img
                className="libr-image"
                src={env.process.env.IMAGE_LIB_URL + item.ten}
                alt={item.ten}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = fail;
                }}
              />
            </div>
          ))}
        </div>
        <div className="libr-modal-foot">
          <Pagination
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
            pageSizeOptions={[30, 50, 100]}
            showSizeChanger={false}
            onChange={(page, size) => handlePaginationChange(page, size)}
          />
        </div>
      </Modal>
      <ModalUpload
        openUpload={openUpload}
        closeUpload={() => setOpenUpload(false)}
        fetchCategoryApi={resetData}
      />
    </>
  );
};
export default LibraryIndex;
