import { FormOutlined } from '@ant-design/icons';
import '../../../styles.css';
import SetUpNotificationEmail from '../modal';
import { useEffect, useState } from 'react';
import { Button, Table, Tooltip, Switch } from 'antd';
import { BreadcrumbComponent } from '../../../../../components/BreadcrumbComponent';
import * as SystemConfigurationApi from '../../../../../api/SystemConfigurationApi/index';
import useThrottle from '../../../../../hook/useThrottle';
import useToast from '../../../../../hook/useToast';

export default function SetUpNotiEmail() {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [dataListEmailTemplate, setDataListEmailTemplate] = useState<SystemConfigurationApi.EmailTemplate[]>([]);
    const [listParamDocument, setListParamDocument] = useState<SystemConfigurationApi.Param[]>([]);
    const [listParamReport, setListParamReport] = useState<SystemConfigurationApi.Param[]>([]);
    const [listParamDuThao, setListParamDuThao] = useState<SystemConfigurationApi.Param[]>([]);
    const [dataChiTiet, setDataChiTiet] = useState<SystemConfigurationApi.EmailTemplate>();
    const pushAlert = useToast();

    useEffect(() => {
        fetchDataEmail()
        fetchDataParam()
    }, [])

    const fetchDataEmail = async () => {
        try {
            const result = await SystemConfigurationApi.getListEmailTemplate();
            if (result.success) {
                setDataListEmailTemplate(result.data)
            }
        } catch (error) {
        }
    }

    const fetchDataParam = async () => {
        try {
            const result = await SystemConfigurationApi.getListParam();
            if (result.success) {
                setListParamDocument(result.data.dataDocument)
                setListParamReport(result.data.dataReport)
                setListParamDuThao(result.data.dataDuThao)
            }
        } catch (error) {
        }
    }

    const fecthDetail = async (id: number) => {
        try {
            let detail = await SystemConfigurationApi.detailEmailTemplate(id)
            if (detail.success) {
                setDataChiTiet(detail.data)
                setIsOpen(true)
            }
        } catch (error) {

        }
    }

    const columns = [
        {
            title: <div className='fix-action-title'>Hành động</div>,
            key: 'stt',
            render: (dataIndex: SystemConfigurationApi.EmailTemplate) =>
                <div className="flex items-center gap-3 fix-center-content">
                    <Tooltip title="Sửa" className='cursor-pointer '>
                        <Button
                            className='cursor-pointer flex items-center border-0 p-0 hover:!text-primary'
                            onClick={() => fecthDetail(dataIndex.id)}
                        >
                            <FormOutlined />
                        </Button>
                    </Tooltip>
                </div>,
            width: '120px',
        },
        {
            title: 'Tên',
            dataIndex: 'ten',
            key: 'ten',
        },
        {
            title: <div className='flex items-center justify-between gap-1 fix-font-th'>Trạng thái</div>,
            key: 'status',
            render: (dataIndex: SystemConfigurationApi.EmailTemplate) => <div className="flex items-center gap-2">
                <Switch checked={dataIndex.status === true ? false : true} onChange={(e) => handleSwitch(dataIndex.id, e)}></Switch>
                <span className='text-sm text-[#4B4B4B]'>{dataIndex.status === true ? 'Ngưng sử dụng' : 'Đang sử dụng'}</span>
            </div>,
            width: '170px'
        },
    ];

    const handleSwitch = useThrottle(async (id: number, status: boolean) => {
        try {
            const result = await SystemConfigurationApi.updateStatus(id, !status);
            if (result.success) {
                pushAlert('Cập nhật trạng thái thành công' ?? "", "success", 2000)
                fetchDataEmail()
            } else {
                pushAlert('Cập nhật không thành công' ?? "", "error", 2000)
            }
        } catch (error) {
            pushAlert('Lỗi' ?? "", "error", 2000)
        }
    }, 2000)
    return (
        <div>
            <BreadcrumbComponent children='Cấu hình hệ thống' title='Cấu hình hệ thống' />
            <div className='px-6 py-6'>
                <SetUpNotificationEmail
                    open={isOpen}
                    handleClose={() => setIsOpen(!isOpen)}
                    dataChiTiet={dataChiTiet}
                    listParamDocument={listParamDocument}
                    listParamReport={listParamReport}
                    listParamDuThao={listParamDuThao}
                />
                <div className={`flex flex-col bg-white rounded-md  pt-2 w-full`}>
                    <div className=" pb-2">
                        <h3 className='text-base font-[600] m-0 px-5'>Thiết lập mẫu email thông báo</h3>
                    </div>
                    <div className='bg-white table-wrapper'>
                        <Table
                            columns={columns}
                            className='custom-table app-nationwide-unit-table'
                            scroll={{ y: 10, x: 1000 }}
                            dataSource={dataListEmailTemplate}
                            pagination={false}
                            showSorterTooltip={false}
                        />
                    </div>
                </div>
            </div>
        </div>


    )
}
